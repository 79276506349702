import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">Tools/PTBookmarkViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTBookmarkViewControllerDelegate bookmarkViewController:didModifyBookmark:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDigitalSignatureTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def PT_SIGNATURE_FIELD_ID</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSignatureAnnotationOptions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSignatureAnnotationOptions.signSignatureFieldsWithStamps</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTAnnot GetUniqueIDAsString]</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}